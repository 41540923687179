import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { AccountWithBalance } from 'cb-wallet-data/stores/Accounts/hooks/useAccountsWithBalances';

import { GAME_ID, GAMIFICATION_URL } from './config';
import { Score } from './useGetUserScore';

export type Level = {
  imageUrl: string;
  prizeImageUrl: string;
  prizeDescription: string;
  ctaUrl: string;
  id: string;
  gameId: string;
  name:
    | 'level-0'
    | 'level-1'
    | 'level-2'
    | 'level-3'
    | 'level-4'
    | 'level-5'
    | 'level-6'
    | 'level-7';
  thresholdPoints: bigint;
  level: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
  description: string;
};

export type LevelsReturnType = {
  currentLevelIdx: number | null;
  levels: Level[];
};

export type Referral = {
  referralCode: string;
  numReferrals: number;
};

export type Badge = {
  id: number;
  name: string;
  gameId: number;
};

export type UserProfileState = {
  numChallengesCompleted: number;
  referralData: Referral;
  levelData: {
    currentLevel: Level;
    nextLevel: Level;
  };
  scoreData: Score;
  badges: Badge[];
  summerPassDisplayBadges: Badge[];
};

export type UserProfileStateV2 = {
  playedOCS: boolean;
  currentScore: bigint;
};

export function useHasCoinbaseProfile(account: AccountWithBalance | undefined) {
  const [hasCBprofile, setHasCBProfile] = useState<boolean | undefined>(undefined);

  const hasCBAccount = useCallback(() => {
    if (!account) {
      return;
    }

    const hasProfile =
      account?.provider === 'com.coinbase.wallet' || account?.provider === 'com.coinbase.keys';

    setHasCBProfile(hasProfile);
  }, [account]);

  useEffect(hasCBAccount, [account, hasCBAccount]);

  return hasCBprofile;
}

export function useGetUserLevels(walletAddress: string) {
  const fetchQuery = async () => {
    const response = await fetch(
      `${GAMIFICATION_URL}/profile/level?userAddress=${walletAddress}&gameId=${GAME_ID}`,
    );

    return response.json();
  };

  const { data, isError, isLoading, error, refetch } = useQuery<LevelsReturnType, Error>(
    ['game_userLevels'],
    fetchQuery,
  );

  return { userLevel: data, isError, isLoading, error, refetch };
}

export async function getProfileState(walletAddress: string | undefined) {
  const response = await fetch(
    `${GAMIFICATION_URL}/profile/state?userAddress=${walletAddress}&gameId=${GAME_ID}`,
  );

  return response.json();
}

export async function getProfileStateV2(walletAddress: string | undefined) {
  const response = await fetch(
    `${GAMIFICATION_URL}/profile/statev2?userAddress=${walletAddress}&gameId=${GAME_ID}`,
  );

  return response.json();
}

export function useGetUserState(walletAddress: string | undefined) {
  const fetchQuery = async () => getProfileState(walletAddress);

  const { data, isError, isLoading, error, refetch } = useQuery<UserProfileState, Error>(
    ['game_userProfileState', walletAddress],
    fetchQuery,
    {
      enabled: !!walletAddress,
      staleTime: 60 * 1000, // 60 seconds
    },
  );

  if (data) {
    data.summerPassDisplayBadges = getSummerpassBadges(data?.badges);
  }

  return { userState: data, isError, isLoading, error, refetch };
}

export function useGetUserStateV2(walletAddress: string | undefined) {
  const fetchQuery = async () => getProfileStateV2(walletAddress);

  const { data, isError, isLoading, error, refetch } = useQuery<UserProfileStateV2, Error>(
    ['game_userProfileStateV2', walletAddress],
    fetchQuery,
    {
      enabled: !!walletAddress,
      staleTime: 60 * 1000, // 60 seconds
    },
  );

  return { userState: data, isError, isLoading, error, refetch };
}

async function optInOCS({ walletAddress }: { walletAddress: string }) {
  const response = await fetch(`${GAMIFICATION_URL}/profile/opt-in`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      gameId: '1',
      userAddress: walletAddress,
    }),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`http request failed with error ${errorText}`);
  }

  return response.json();
}

export function useOptInOCS() {
  const { mutate, isLoading, error, data } = useMutation({
    mutationFn: async (params: { walletAddress: string }) => optInOCS(params),
  });

  return { mutate, isLoading, error, data };
}

// We only want to show the highest badge for each of the 10, 50, 100, and 1000 transaction badges
function getSummerpassBadges(badges: Badge[]) {
  if (!badges) return [];

  // Find the maximum badge ID among 7, 8, 9, and 10
  function findMaxBadge(max: number, badge: Badge) {
    const badgeId = Number(badge.id);
    return badgeId >= 7 && badgeId <= 10 ? Math.max(max, badgeId) : max;
  }

  // Filter out badges 7, 8, 9, and 10, except for the maximum badge
  function filterBadges(badge: Badge) {
    const badgeId = Number(badge.id);
    return (
      (badgeId !== 7 && badgeId !== 8 && badgeId !== 9 && badgeId !== 10) || badgeId === maxTxBadge
    );
  }

  const maxTxBadge = badges.reduce(findMaxBadge, -1);

  const filteredBadges = badges.filter(filterBadges);

  return filteredBadges;
}
